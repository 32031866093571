import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import background from "../svg/about-us-bg.png";
import {Link} from "react-router-dom";
import logoIk from "../svg/Logo-IK.png";
import logoIEk from "../svg/Logo-IEK.png";
import logoHCTV from "../svg/Logo-HCTV.png";
import logoNEU from "../svg/Logo-NEU.png";
import logoTCME from "../svg/Logo-TCME-1.png";
import logoWipaed from "../svg/Logo_Wipaed-1.png";
import logoEURONET from "../svg/Logo-EURONET.png";










const About = (props) => {
  const [t,] = useTranslation("global");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 970);



    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 970);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function handleSetCurrentPage(page) {
        props.setCurrentPage(page);
    }

    const [currentIndex, setCurrentIndex] = useState(0);

    const slides = [
        {
            id: 1,
            logo: logoNEU,
            text: t("about.NEU"),
        },
        {
            id: 2,
            logo: logoIk,
            text: t("about.IK"),
        },
        {
            id: 3,
            logo: logoIEk,
            text: t("about.IEK"),
        },
        {
            id: 4,
            logo: logoTCME,
            text: t("about.TCME"),
        },
        {
            id: 5,
            logo: logoHCTV,
            text: t("about.HCTV"),
        },
        {
            id: 6,
            logo: logoWipaed,
            text: t("about.WIPAED"),
        },
        {
            id: 7,
            logo: logoEURONET,
            text: t("about.EURONET"),
        },
        {
            id: 8,
            logo: logoTCME,
            text: t("about.TCME_2"),
        },
    ];

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) =>
            (prevIndex - 1 + slides.length) % slides.length
        );
    };

    return (
        <>
            {!isMobile && (
                <>
                    <div className="App mx-auto">
                        <div style={{
                            height:"100vh",
                            backgroundImage: `url(${background})`, // Set background image
                            backgroundSize:"cover",
                            backgroundPosition: "left",
                            backgroundRepeat: "repeat",
                            width:"100%",
                            position:"fixed",
                        }}>


                            <div className="drop-shadow text-black text-center lg:text-6xl text-6xl" style={{
                                fontFamily: "Poppins, sans-serif",
                                textAlign: "center",
                                alignItems: "center",
                                position:"relative",
                                color:"white",
                                right:0,
                                width:"50vw",
                                float:"right",
                                height:"100vh",
                                maxHeight:"200vh",
                                paddingLeft:"5%",
                                paddingRight:"5%",
                                top:"30vh"

                            }}>

                                <div style={{ alignItems: "center", fontSize: "5vh"}}>
                                    <div style={{
                                        display:"flex"
                                    }}>
                                        <div style={{
                                            display:"flex",
                                            marginRight:"10%"
                                        }}>
                                            {t("about.title")}
                                        </div>
                                        <div style={{
                                            display:"flex", flex:1, alignItems:"center", width:"100vw"
                                        }}>
                                            <div style={{ width: "100%", height: "2px", backgroundColor: "white", position:"absolute" }}></div>
                                        </div>
                                    </div>
                                    <div style={{
                                        alignItems: "start",
                                        fontFamily: "Poppins, sans-serif",
                                        fontSize: "2vh",
                                        marginRight: "20vh",
                                        width:"100%",
                                        left:0
                                    }}>
                                        <div
                                            style={{
                                                textAlign:"justify",
                                                fontFamily: "Poppins, sans-serif",
                                                fontSize: "1.7vh",
                                                marginTop: "3vh",
                                                left:0
                                            }}
                                        >
                                            <p>
                                                {t('about.description')}
                                            </p>
                                            <p>
                                                {t('about.partners')}
                                            </p>
                                            <div>
                                                    <div className="relative w-full h-auto">
                                                        <div className="overflow-hidden">
                                                            <div
                                                                className="flex transition-transform duration-500 ease-in-out"
                                                                style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                                                            >
                                                                {slides.map((slide) => (
                                                                    <div
                                                                        key={slide.id}
                                                                        className={`min-w-full h-70 flex items-center ${slide.color}`}
                                                                    >
                                                                        <div className="container mx-auto px-20 flex items-center">
                                                                            {/* Logo */}
                                                                            <div className="w-1/3 flex justify-center p-5">
                                                                                <img
                                                                                    src={slide.logo}
                                                                                    alt={`Logo ${slide.id}`}
                                                                                    className="w-40 h-40 object-contain"
                                                                                />
                                                                            </div>
                                                                            {/* Text */}
                                                                            <div className="w-2/3 text-white text-lg font-medium">
                                                                                {slide.text}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        {/* Controls */}
                                                        <div className="absolute inset-0 flex justify-between items-center px-4">
                                                            <button
                                                                onClick={prevSlide}
                                                                className="bg-white text-gray-700 p-2 rounded-full shadow hover:bg-gray-100"
                                                            >
                                                                &larr;
                                                            </button>
                                                            <button
                                                                onClick={nextSlide}
                                                                className="bg-white text-gray-700 p-2 rounded-full shadow hover:bg-gray-100"
                                                            >
                                                                &rarr;
                                                            </button>
                                                        </div>
                                                    </div>
                                            </div>
                                            <div>
                                                <div style={{ width:"100%", justifyContent: 'space-between' }}>
                                                    <div style={{width:"50%", float:"left"}}>
                                                        <Link
                                                            to={"/"}
                                                            onClick={()=>{handleSetCurrentPage('learning-units')}}
                                                        >
                                                            <button className="slider-button-browse-units" style={{ position: "relative", background:"rgba(0, 230, 118,1)", color:"black", width:"90%", paddingRight:"5%", paddingLeft:"5%", paddingTop:"5%", paddingBottom:"5%"}} onClick={props.browseUnits}>
                                                                <p><b>{t("initial_banner.browse_units")}</b></p>
                                                            </button>
                                                        </Link>
                                                    </div>
                                                    <div style={{width:"50%" , float:"right"}}>
                                                        <Link
                                                            to={"/contacts"}
                                                            onClick={()=>{handleSetCurrentPage('contacts')}}
                                                        >
                                                            <button className="slider-button-browse-units" style={{ float:"right", position: "relative", background:"rgba(0, 230, 118,1)", color:"black", width:"90%",paddingRight:"5%", paddingLeft:"5%", paddingTop:"5%", paddingBottom:"5%"}} onClick={props.browseUnits}>
                                                                <p><b>{t("about.contact_us")}</b></p>
                                                            </button>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {isMobile && (
                <>
                    <div className="App mx-auto" style={{height:"100vh"}}>
                        <div style={{
                            height:"110vh",
                            backgroundImage: `url(${background})`, // Set background image
                            backgroundSize:"cover",
                            backgroundRepeat: "repeat",
                            width:"100%",
                            position:"fixed",
                        }}>
                            <div style={{
                                height:"100vh",
                                background:"rgba(0,0,0,0.6)",
                                width:"100%",
                                position:"fixed",
                            }}></div>


                            <div className="drop-shadow text-black text-center lg:text-6xl text-6xl" style={{
                                fontFamily: "Poppins, sans-serif",
                                textAlign: "center",
                                alignItems: "center",
                                position:"relative",
                                color:"white",
                                width:"100vw",
                                height:"100vh",
                                maxHeight:"200vh",
                                paddingLeft:"5%",
                                paddingRight:"5%",
                                top:"20vh"

                            }}>

                                <div style={{ alignItems: "center", fontSize: "5vh"}}>
                                    <div style={{
                                        display:"flex"
                                    }}>
                                        <div style={{
                                            display:"flex",
                                        }}>
                                            {t("about.title")}
                                        </div>

                                    </div>
                                    <div style={{
                                        alignItems: "center",
                                        fontFamily: "Poppins, sans-serif",
                                        fontSize: "2vh",
                                        width:"100%",
                                    }}>
                                        <div
                                            style={{
                                                textAlign:"justify",
                                                fontFamily: "Poppins, sans-serif",
                                                fontSize: "1.7vh",
                                                marginTop: "3vh",
                                                left:0
                                            }}
                                        >
                                            <p>
                                                {t('about.description')}
                                            </p>
                                            <p>
                                                {t('about.partners')}
                                            </p>
                                            <div>
                                                <div className="relative w-full">
                                                    <div className="overflow-hidden">
                                                        <div
                                                            className="flex transition-transform duration-500 ease-in-out"
                                                            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                                                        >
                                                            {slides.map((slide) => (
                                                                <div
                                                                    key={slide.id}
                                                                    className={`min-w-full h-80 flex items-center ${slide.color}`}
                                                                >
                                                                    <div className="container mx-auto px-4 sm:px-10 flex flex-col sm:flex-row items-center">
                                                                        {/* Logo */}
                                                                        <div className="w-full sm:w-1/3 flex justify-center mb-4 sm:mb-0">
                                                                            <img
                                                                                src={slide.logo}
                                                                                alt={`Logo ${slide.id}`}
                                                                                className="w-40 h-40 object-contain"
                                                                            />
                                                                        </div>
                                                                        {/* Text */}
                                                                        <div className="w-full sm:w-2/3 text-white text-lg font-medium text-center sm:text-left">
                                                                            {slide.text}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    {/* Controls */}
                                                    <div className="absolute inset-0 flex justify-between items-center px-4">
                                                        <button
                                                            onClick={prevSlide}
                                                            className="bg-white text-gray-700 p-2 rounded-full shadow hover:bg-gray-100"
                                                        >
                                                            &larr;
                                                        </button>
                                                        <button
                                                            onClick={nextSlide}
                                                            className="bg-white text-gray-700 p-2 rounded-full shadow hover:bg-gray-100"
                                                        >
                                                            &rarr;
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div style={{ width:"100%", justifyContent: 'space-between' }}>
                                                    <div style={{width:"50%", float:"left"}}>
                                                        <Link
                                                            to={"/"}
                                                            onClick={()=>{handleSetCurrentPage('learning-units')}}
                                                        >
                                                            <button className="slider-button-browse-units" style={{ position: "relative", background:"rgba(0, 230, 118,1)", color:"black", width:"90%", paddingRight:"5%", paddingLeft:"5%", paddingTop:"5%", paddingBottom:"5%"}} onClick={props.browseUnits}>
                                                                <p><b>{t("initial_banner.browse_units")}</b></p>
                                                            </button>
                                                        </Link>
                                                    </div>
                                                    <div style={{width:"50%" , float:"right"}}>
                                                        <Link
                                                            to={"/contacts"}
                                                            onClick={()=>{handleSetCurrentPage('contacts')}}
                                                        >
                                                            <button className="slider-button-browse-units" style={{ float:"right", position: "relative", background:"rgba(0, 230, 118,1)", color:"black", width:"90%",paddingRight:"5%", paddingLeft:"5%", paddingTop:"5%", paddingBottom:"5%"}} onClick={props.browseUnits}>
                                                                <p><b>{t("about.contact_us")}</b></p>
                                                            </button>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>


  );
};

export default About;
